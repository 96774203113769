@tailwind components;
@tailwind utilities;
@tailwind variants;

body {
  @apply m-0 font-sans;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
